<template>
        <v-text-field v-if="rule.type === 'text'" v-model="value[rule.name]"
                        :rules="requiredRule(rule)"
                        :label="rule.label"
                        :prefix="rule.required ? '*' : ''"></v-text-field>
        <v-text-field v-else-if="rule.type === 'number' && rule.name !== 'win_cap' && rule.name != 'promotion_cap'" type="number" v-on:keypress="onlyDigits($event)" v-model.number="value[rule.name]"
                        :rules="requiredRule(rule)"
                        :label="rule.label"
                        :values="values[rule.name]"
                        @input="$set(values, rule.name, parseFloat($event))"
                        :min="rule.min ? rule.min : 0"
                        :max="rule.max ? rule.max : 999999999999"
                        :prefix="rule.required ? '*' : ''"></v-text-field>
        <v-text-field v-else-if="rule.type === 'number' && rule.name == 'win_cap' " type="number" v-model.number="value[rule.name]"
                        :rules="requiredWinCap(rule)"
                        :values="values[rule.name]"
                        @input="$set(values, rule.name, parseFloat($event))"
                        :label="rule.label"
                        :prefix="rule.required ? '*' : ''"></v-text-field>
        <v-text-field v-else-if="rule.type === 'number' && rule.name == 'promotion_cap' " type="number" v-model.number="value[rule.name]"
                        :rules="requiredPromoCap(rule)"
                        :values="values[rule.name]"
                        @input="$set(values, rule.name, parseFloat($event))"
                        :label="rule.label"
                        :prefix="rule.required ? '*' : ''"></v-text-field>
        <v-textarea v-else-if="rule.type === 'textarea'"
                    v-model="value[rule.name]"
                    :rules="requiredRule(rule)"
                    :label="rule.label"
                    :prefix="rule.required ? '*' : ''"></v-textarea>
        <v-menu
            v-else-if="rule.type === 'date' && rule.name == 'begins_at'"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
        >
            <template v-slot:activator="{ on }">
                <v-text-field v-model="value[rule.name]"
                                :rules="requiredDateBegins(rule)"
                                :label="rule.label"
                                :prefix="rule.required ? '*' : ''"
                                readonly
                                v-on="on">
                </v-text-field>
            </template>
            <v-date-picker v-model="value[rule.name]"></v-date-picker>
        </v-menu>
        <v-menu
            v-else-if="rule.type === 'date' && rule.name == 'expires_at'"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
        >
            <template v-slot:activator="{ on }">
                <v-text-field v-model="value[rule.name]"
                                :rules="requiredDateEnds(rule)"
                                :min="today"
                                :label="rule.label"
                                :prefix="rule.required ? '*' : ''"
                                readonly
                                v-on="on">
                </v-text-field>
            </template>
            <v-date-picker v-model="value[rule.name]"></v-date-picker>
        </v-menu>
        <v-menu
            v-else-if="rule.type === 'date'"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
        >
            <template v-slot:activator="{ on }">
                <v-text-field v-model="value[rule.name]"
                                :rules="requiredRule(rule)"
                                :label="rule.label"
                                :prefix="rule.required ? '*' : ''"
                                readonly
                                v-on="on">
                </v-text-field>
            </template>
            <v-date-picker v-model="value[rule.name]"></v-date-picker>
        </v-menu>
</template>

<script>
export default {
  props: [
    'rule', 'value'
  ],

  data () {
    return {
      formValues: this.value,
      values: ['win_cap', 'promotion_cap'],
      form: null
    }
  },

  mounted () {
  },

  computed: {
    maxCap () {
      return process.env.VUE_APP_MAX_CAP
    },
    today () {
      const today = new Date().toISOString().slice(0, 10)
      return today
    }
  },

  methods: {
    requiredRule (rule) {
      if (rule.required || rule.isRequired) {
        return [
          value => !!value || 'Field is required.'
        ]
      }

      return []
    },

    requiredWinCap (rule) {
      const rules = []

      if (rule.required || rule.isRequired) {
        const r =
          v => !!v || 'Win Cap is required'
        rules.push(r)
      }

      const ltPromoCap =
        v => (!this.value.promotion_cap || v <= this.value.promotion_cap) || 'must be lower than Promotion Cap'

      rules.push(ltPromoCap)
      return rules
    },

    requiredPromoCap (rule) {
      const rules = []

      if (rule.required || rule.isRequired) {
        const r =
          v => !!v || 'Promotion Cap is required'
        rules.push(r)
      }

      const htWinCap =
        v => (!this.value.win_cap || v >= this.value.win_cap) || 'must be higher than Win Cap'
      rules.push(htWinCap)

      const ltEnvMaxCap =
        v => v <= this.maxCap || 'must be lower than app maximum allowed amount per promotion €' + this.maxCap
      rules.push(ltEnvMaxCap)

      return rules
    },

    requiredDateBegins (rule) {
      const rules = []

      if (rule.required || rule.isRequired) {
        const r =
          v => !!v || ' date start is required'
        rules.push(r)
      }

      const gtToday =
         v => v >= this.today || new Date(this.begins_at) + ' must be equal or higher than today ' + this.today
      rules.push(gtToday)

      const ltDateEnds =
        v => v <= this.value.expires_at || 'must be lower than Expiration Date'

      rules.push(ltDateEnds)
      return rules
    },

    requiredDateEnds (rule) {
      const rules = []

      if (rule.required || rule.isRequired) {
        const r =
          v => !!v || ' date start is required'
        rules.push(r)
      }

      const ltDateEnds =
        v => v >= this.value.begins_at || 'must be higher than Begginning Date'

      rules.push(ltDateEnds)
      return rules
    },

    validate () {
    },

    onlyDigits (e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[0-9]+$/.test(char)) {
        return true
      } else {
        e.preventDefault()
      }
    }

  },

  watch: {
    value: {
      handler (val) {
        this.$emit('input', this.value)
      },
      deep: true
    }
  }
}

</script>
