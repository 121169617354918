<template>
    <v-card :elevation="5">
        <v-stepper
            v-model="stepper"
            vertical
        >
            <v-stepper-step
                :complete="stepper > 1"
                step="1"
            >
                Create Promotion
            </v-stepper-step>
            <v-stepper-content step="1">
                <v-form ref="createPromotionForm">
                    <v-select
                        v-model="selectedPromotionType"
                        :items="promotionTypes"
                        item-text="name"
                        item-value="slug"
                        :rules="[v => !!v || 'You must choose a promotion type']"
                        label="Promotion Type"
                        required
                    ></v-select>

                    <PromotionRules
                      v-for="field in promotionFormFields" :key="field.name"
                      :rule="field"
                      v-model="promotionForm"
                    />

                    <v-btn
                        color="success"
                        class="mt-8"
                        @click="selectGamesStep"
                    >
                        Next
                    </v-btn>
                </v-form>
            </v-stepper-content>

            <v-stepper-step
                :complete="stepper > 2"
                step="2"
            >
                Select Games
            </v-stepper-step>
            <v-stepper-content step="2">
                <v-form ref="gamesConfigForm">
                    <v-text-field
                      v-if="selectedPromotionType == 'free_rounds'"
                      v-model="nSpins"
                      label='Initial Spin Default'
                      type="number"
                    ></v-text-field>
                    <v-select
                      v-if="selectedPromotionType == 'free_rounds'"
                      v-model="bet"
                      :items="bets"
                      label="Initial Bets Default"
                      type="number"
                    ></v-select>

                    <v-text-field
                      v-if="selectedPromotionType == 'fun_bonus'"
                      v-model="balance"
                      label='Initial Balance Default'
                      type="number"
                    ></v-text-field>

                    <v-text-field
                      v-if="selectedPromotionType == 'fun_bonus'"
                      v-model="staking"
                      label='Initial Staking Default'
                      type="number"
                    ></v-text-field>

                      <v-autocomplete
                        v-model="selectedGames"
                        :items="games"
                        :disabled="loadingGames"
                        label="Games"
                        item-text="name"
                        item-value="id"
                        multiple
                        chips
                        deletable-chips
                        required
                        ref="selectedGamesList"
                        auto-select-first
                    >
                        <!-- <template #selection="{ item }">
                          <v-chip
                            :disabled="item.disabled"
                            :close="!item.disabled"
                            @click:close="deleteGame(item)"
                            >
                              {{item.name}}
                            </v-chip>
                        </template> -->
                        <template v-slot:prepend-item>
                            <v-list-item
                                ripple=""
                                @click="toggleGamesSelection"
                            >
                                <v-list-item-action>
                                    <v-icon :color="selectedGames.length > 0 ? 'indigo darken-4' : ''">
                                        {{ iconGames }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Select All
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                    </v-autocomplete>

                    <div v-for="(selectedGame, index) in selectedGamesConfigs" :key="selectedGame.gameSlug">
                        <v-divider class="my-4" inset></v-divider>
                        <h3>{{ games.find(game => game.slug === selectedGamesConfigs[index].gameSlug).name }}</h3>
                        <div v-for="(field, index) in selectedGame.fields" :key="field.name">
                            <v-text-field v-if="field.type === 'text'"
                                          v-model="field.value"
                                          :rules="requiredRule(field)"
                                          :label="field.label"
                                          :prefix="field.isRequired ? '*' : ''"></v-text-field>
                            <v-text-field v-if="field.type === 'number'" type="number"
                                          v-model.number="field.value"
                                          :rules="requiredRule(field)"
                                          :label="field.label"
                                          :prefix="field.isRequired ? '*' : ''"
                                          :id="index"></v-text-field>
                            <v-textarea v-else-if="field.type === 'textarea'"
                                        v-model="field.value"
                                        :rules="requiredRule(field)"
                                        :label="field.label"
                                        :prefix="field.isRequired ? '*' : ''"></v-textarea>
                            <v-select v-else-if="field.type === 'select'"
                                      v-model="field.value"
                                      :rules="requiredRule(field)"
                                      :items="field.values"
                                      :label="field.label"
                                      :prefix="field.isRequired ? '*' : ''"
                                      :type="field.type != undefined ? field.type : 'text' "
                            >
                            </v-select>
                        </div>
                    </div>

                    <v-btn
                        color="info"
                        class="mt-8 mr-4"
                        @click="stepper=1"
                    >
                        Previous
                    </v-btn>
                    <v-btn
                        color="success"
                        class="mt-8"
                        :disabled="!hasGames"
                        @click="selectPlayersStep"
                    >
                        Next
                    </v-btn>
                </v-form>
            </v-stepper-content>

            <v-stepper-step
                :complete="stepper > 3"
                step="3"
            >
                Select Players
            </v-stepper-step>
            <v-stepper-content step="3">
                  <v-row>
                      <v-col cols="12" sm="6">
                          <v-autocomplete
                              v-model="selectedPlayers"
                              :items="players"
                              label="Players"
                              :filter="filterObject"
                              item-value="id"
                              multiple
                              chips
                              auto-select-first
                              required
                          >
                            <template slot="selection" slot-scope="data">
                              <v-chip
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="removePlayer(data.item)"
                              >
                                {{ data.item.username }} - {{ data.item.contract_number }}
                              </v-chip>
                            </template>
                            <template slot="item" slot-scope="data">
                              {{ data.item.username }} - {{ data.item.contract_number }}
                            </template>
                            <template v-slot:prepend-item>
                                <v-list-item
                                    ripple
                                    @click="togglePlayersSelection"
                                >
                                    <v-list-item-action>
                                        <v-icon :color="selectedPlayers.length > 0 ? 'indigo darken-4' : ''">
                                            {{ iconPlayers }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Select All
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                          </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6">
                          <CreatePlayer
                            @playerCreated="pushPlayer"
                            />
                      </v-col>
                  </v-row>

                  <v-btn
                      color="info"
                      class="mt-8 mr-4"
                      @click="stepper=2"
                  >
                      Previous
                  </v-btn>
                  <v-btn
                      color="success"
                      class="mt-8"
                      :disabled="!this.selectedPlayers.length > 0"
                      @click="createPromotion"
                  >
                      Create Promotion
                  </v-btn>
            </v-stepper-content>
        </v-stepper>
    </v-card>
</template>

<script>
import PromotionRules from './PromotionRules'
import CreatePlayer from '../players/CreatePlayer'
// import autocomplete from '../../components/ui/autocomplete'

export default {
  components: {
    PromotionRules,
    CreatePlayer
    // autocomplete
  },
  data () {
    return {
      stepper: 1,
      promotionTypes: [],
      selectedPromotionType: null,
      promotionForm: { priority: 5 },
      promotionFormFields: [],
      intialPromotionFormFields: [{ name: 'priority', label: 'Priority', type: 'number', required: true, min: 1, max: 10, value: 5 }],
      games: [],
      selectedGames: [],
      selectedGamesConfigs: [],
      responseGamesConfigs: [],
      searchGame: null,
      gameItem: null,
      loadingGames: false,
      players: [],
      selectedPlayers: [],
      hasPromotionRules: false,
      hasGames: false,
      hasPlayers: false,
      nSpins: null,
      bet: null,
      bets: [50, 100, 150, 200, 250, 300],
      staking: null,
      balance: null
    }
  },

  watch: {
    value () {
      this.gameItem = this.value
    },
    promotionFormFields (newValue) {
      newValue.map(field => {
        this.promotionForm[field.name] = field.value !== undefined ? field.value : null
      })
      this.hasPromotionRules = true
    },
    selectedPromotionType () {
      this.hasPromotionRules = false
      this.$axios.get(`api/promotionTypes/${this.selectedPromotionType}`)
        .then(response => {
          this.promotionFormFields = [...this.intialPromotionFormFields, ...response.data.form_fields]
        })
    },
    selectedGames (val, oldVal) {
      this.loadingGames = true

      const newGames = val.filter(x => !oldVal.includes(x))
      const delGames = oldVal.filter(x => !val.includes(x))

      // tracks and deletes games from Config array, search is made by slug
      delGames.forEach(del => {
        const slug = this.games.find(g => g.name === del).slug
        const index = this.selectedGamesConfigs.indexOf(this.selectedGamesConfigs.find(f => f.gameSlug === slug))
        if (index > -1) {
          this.selectedGamesConfigs.splice(index, 1)
        }
      })

      const axiosArray = []
      this.$refs.selectedGamesList.blur()

      newGames.forEach(game => {
        if (this.selectedGames.find(selectedGame => selectedGame === game)) {
          const slug = this.games.find(g => g.name === game).slug

          if (!slug) {
            console.log('something went wrong processing the games, selectedGames for game >', game)
            return false
          }

          const existingResponse = this.responseGamesConfigs.indexOf(this.responseGamesConfigs.find(g => g.gameSlug === slug))
          if (existingResponse > -1) {
            let canAdd = true
            for (const v in this.selectedGamesConfigs) {
              if (this.selectedGamesConfigs[v].gameSlug === this.responseGamesConfigs[existingResponse].gameSlug) {
                canAdd = false
              }
            }

            if (canAdd) {
              // update Response cached bet, nspins, staking, balance
              if (this.responseGamesConfigs[existingResponse].fields.nSpins !== undefined) {
                this.responseGamesConfigs[existingResponse].fields.nSpins.value = this.nSpinsValue ? this.nSpinsValue * 1 : null
              }
              if (this.responseGamesConfigs[existingResponse].fields.bet !== undefined) {
                this.responseGamesConfigs[existingResponse].fields.bet.value = this.responseGamesConfigs[existingResponse].fields.bet.values.indexOf(this.betValue) === -1 ? this.responseGamesConfigs[existingResponse].fields.bet.values[0] : this.betValue
              }

              if (this.responseGamesConfigs[existingResponse].fields.staking !== undefined) {
                this.responseGamesConfigs[existingResponse].fields.staking.value = this.staking ? this.staking * 1 : null
              }

              if (this.responseGamesConfigs[existingResponse].fields.balance !== undefined) {
                this.responseGamesConfigs[existingResponse].fields.balance.value = this.balance ? this.balance * 1 : null
              }

              this.selectedGamesConfigs.push(this.responseGamesConfigs[existingResponse])
              return true
            }
          }

          const promise = this.$axios.get(`api/promotionTypes/${this.selectedPromotionType}/games/${slug}`)
            .then(response => {
              // saving response for single render page cache
              this.responseGamesConfigs.push(response.data)

              // this is dumb IK but can't figure it out on how to make vuetify v-for work with an Object or Set propper ( it was designed mainly for Arrays only. Objects are supported but not getting there)
              // issues are item duplication

              if (response.data.fields.nSpins !== undefined) {
                response.data.fields.nSpins.value = this.nSpinsValue * 1
              }
              /*
               *special care with betValue;
               * just because you chose a value from the default array doesn't mean that picked value actually exists in the bets
               */
              if (response.data.fields.bet !== undefined) {
                response.data.fields.bet.value = response.data.fields.bet.values.indexOf(this.betValue) === -1 ? response.data.fields.bet.values[0] : this.betValue
              }

              if (response.data.fields.staking !== undefined) {
                response.data.fields.staking.value = this.staking * 1
              }

              if (response.data.fields.balance !== undefined) {
                response.data.fields.balance.value = this.balance * 1
              }

              let canAdd = true
              for (const v in this.selectedGamesConfigs) {
                if (this.selectedGamesConfigs[v].gameSlug === response.data.gameSlug) {
                  canAdd = false
                }
              }

              if (canAdd) {
                this.selectedGamesConfigs.push(response.data)
              }
            })

          axiosArray.push(promise)
        }
      })

      Promise.all(axiosArray).finally(values => {
        this.loadingGames = false
      }).catch(function (err) {
        console.log('caught this error', err)
      })

      this.hasGames = this.selectedGames.length > 0
    }
  },

  computed: {
    nSpinsValue () {
      return this.nSpins
    },
    betValue () {
      return this.bet
    },
    allGamesSelected () {
      return this.selectedGames.length === this.games.length
    },
    someGamesSelected () {
      return this.selectedGames.length > 0 && !this.allGamesSelected
    },
    allPlayersSelected () {
      return this.selectedPlayers.length === this.players.length
    },
    somePlayersSelected () {
      return this.selectedPlayers.length > 0 && !this.allPlayersSelected
    },
    iconGames () {
      if (this.allGamesSelected) return 'mdi-close-box'
      if (this.someGamesSelected) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    iconPlayers () {
      if (this.allPlayersSelected) return 'mdi-close-box'
      if (this.somePlayersSelected) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    maxCap () {
      return process.env.VUE_APP_MAX_CAP
    }
  },

  mounted () {
    this.fetchPromotionTypes()
  },

  methods: {
    requiredRule (field) {
      if (field.required || field.isRequired) {
        return [
          value => !!value || 'Field is required.'
        ]
      }

      return []
    },
    selectGamesStep () {
      if (!this.$refs.createPromotionForm.validate() || !this.hasPromotionRules) {
        return
      }

      this.fetchGames().then(result => {
        this.stepper = 2
      })
    },
    selectPlayersStep () {
      if (!this.$refs.gamesConfigForm.validate() || !this.hasGames) {
        return
      }

      this.fetchPlayers()
      this.stepper = 3
    },
    createPromotion () {
      if (this.selectedPlayers.length <= 0) {
        return
      }

      this.$axios.post(`api/promotions/${this.selectedPromotionType}`, this.promotionFormData()).then(response => {
        this.$router.push({ name: 'promotions.index' })
      })
    },
    pushPlayer (player) {
      this.fetchPlayers()
      this.selectedPlayers.push(player.id)
    },
    fetchPlayers () {
      this.$axios.get('api/players').then(response => {
        this.players = response.data
      })
    },
    fetchGames () {
      const promise = new Promise((resolve, reject) => {
        this.$axios(`api/promotionTypes/${this.selectedPromotionType}/games`).then(response => {
          this.games = response.data
          resolve('fetch games success')
        })
      })
      return promise
    },
    fetchPromotionTypes () {
      const promise = new Promise((resolve, reject) => {
        this.$axios('api/promotionTypes').then(response => {
          this.promotionTypes = response.data
          resolve('fetch promotion types success')
        })
      })
      return promise
    },
    promotionFormData () {
      const formData = {}
      Object.keys(this.promotionForm).forEach(key => {
        const value = this.promotionForm[key]
        if (value !== undefined && value !== null) {
          formData[key] = value
        }
      })

      formData.players = this.selectedPlayers

      const games = []
      this.selectedGamesConfigs.forEach(gameConfig => {
        const game = {
          game_slug: gameConfig.gameSlug,
          fields: {}
        }
        Object.keys(gameConfig.fields).forEach(key => {
          game.fields[key] = gameConfig.fields[key].value
        })

        games.push(game)
      })
      formData.games = games

      return formData
    },
    toggleGamesSelection () {
      if (this.loadingGames) {
        console.log('gonna skip this try cause games are still being loaded in the background')
        return false
      }

      this.$nextTick(() => {
        if (this.allGamesSelected) {
          this.selectedGames = []
        } else {
          this.selectedGames = this.games.map(item => item.name)
        }
      })
    },
    togglePlayersSelection () {
      this.$nextTick(() => {
        if (this.selectedPlayers.length === this.players.length) {
          this.selectedPlayers = []
        } else {
          this.selectedPlayers = this.players.map(player => player.id)
        }
      })
    },

    deleteGame (game) {
      console.log('deleting game', game)
      const index = this.selectedGames.indexOf(game.name)
      if (index > -1) {
        this.selectedGames.splice(index, 1)
      }
    },

    gameInputListener () {
      this.searchGame = null
    },
    /*
    * enabling returning a positive match betweent the query "cesar" and the item.name "César Silva". accented characters
    */
    customFilter (item, queryText, itemText) {
      const textOne = item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      const searchText = queryText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      return (
        textOne.indexOf(searchText) > -1
      )
    },
    filterObject (item, queryText, itemText) {
      return (
        item.username.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.contract_number.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    removePlayer (item) {
      console.log('removing player')
      console.log(item)
      const index = this.players.indexOf(item.username)
      if (index >= 0) this.players.splice(index, 1)
    }
  }
}
</script>

<style scoped>

</style>
