<template>
    <v-form>
        <v-dialog
            v-model="createPlayerDialog"
            persistent
            max-width="600px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                    New Player
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="headline">New Player</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-text-field
                            label="Contract Number"
                            v-model="playerForm.contract_number"
                            required
                            :rules="[v => !!v || 'Contract Number is required.']"
                        ></v-text-field>
                        <v-text-field
                            label="Name"
                            v-model="playerForm.name"
                        ></v-text-field>
                        <v-text-field
                            label="Username"
                            v-model="playerForm.username"
                        ></v-text-field>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="createPlayerDialog = false"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="createPlayer"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template>

<script>
export default {
  data () {
    return {
      myData: 'my|your Data',
      createPlayerDialog: false,
      playerForm: {
        contract_number: '',
        name: '',
        username: ''
      }
    }
  },

  methods: {
    createPlayer () {
      this.$axios.post('api/players', this.playerForm).then(response => {
        this.fetchPlayers()
        this.createPlayerDialog = false
        this.playerForm = {
          contract_number: '',
          name: '',
          username: ''
        }
        this.$emit('playerCreated', response.data)
      }).catch(
        result => {
          this.$emit('playerCreatedError')
        }
      )
    },
    fetchPlayers () {
      this.$axios.get('api/players').then(response => {
        this.players = response.data
      })
    }
  }
}
</script>
