<template>
    <v-chip
        :color="colors[status]"
        text-color="white"
        pill
    >{{ status }}
    </v-chip>
</template>

<script>
export default {
  name: 'PromotionStatusIndicator',
  props: {
    status: {
      type: String,
      required: true,
      validator: (val) => ['ended', 'upcoming', 'active', 'canceled'].includes(val)
    }
  },

  data () {
    return {
      colors: {
        ended: 'red',
        upcoming: 'blue',
        active: 'green'
      }
    }
  }
}
</script>

<style scoped>

</style>
