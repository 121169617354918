<template>
    <div v-if="promotion">
        <v-card :elevation="5">
            <v-card-title class="subheading font-weight-bold">
                <span class="mr-3">Promotion</span>
                  <promotion-status-indicator :status="promotion.status" class="mr-2"></promotion-status-indicator>
                   <v-btn
                    color="blue"
                    text-color="white"
                    pill
                    @click="update(promotion)"
                    v-if="!$route.params.contractId && ['active', 'upcoming'].includes(promotion.status)"
                    class="mr-2">
                      Update
                    </v-btn>
                   <v-dialog
                      class="mr-2"
                      v-model="dialog"
                      width="500"
                      v-if="!$route.params.contractId && promotion.is_canceled === null"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="red lighten-2"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                        Cancel
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Sure to Cancel Promotion?
                        </v-card-title>

                        <v-card-text class="mt-10">
                          Already started sessions will still be able to finish their current Game and collect possible earnings.
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                           <v-btn
                            color="primary"
                            text
                            @click="dialog = false"
                          >
                            close window
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            text
                            @click="cancel(promotion)"
                          >
                            Sure!
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                   </v-dialog>

                   <span v-else-if="!$route.params.contractId">
                    Canceled {{ promotion.date_canceled }}
                   </span>
            </v-card-title>

            <v-divider></v-divider>

            <v-list dense>
                <div v-for="key in promotionKeys" :key="key.key">
                    <v-list-item v-if="$dotProp.has(promotion, key.key)">
                        <v-row>
                            <v-col cols="3">
                                <v-list-item-content class="font-weight-bold">{{ key.label }}:</v-list-item-content>
                            </v-col>
                            <v-col>
                                <v-list-item-content class="align-end">
                                    {{ $dotProp.get(promotion, key.key) || '---' }}
                                </v-list-item-content>
                            </v-col>
                        </v-row>
                    </v-list-item>
                </div>
            </v-list>

            <v-divider></v-divider>

                   <!-- <v-card-actions>
                        <v-btn
                            color="deep-purple lighten-2"
                            text
                            :to="{name: 'promotions.show', params: {promotion: promotion.promo_code}}"
                        >
                            Details
                        </v-btn>
                    </v-card-actions> -->
        </v-card>

        <v-card class="mt-10" :elevation="5">
            <v-card-title class="subheading font-weight-bold">
                Games
            </v-card-title>

            <v-divider></v-divider>

            <v-list dense>
                <v-list-item v-for="(gamePromotion, index) in promotion.game_promotions" :key="index">
                    <v-row>
                        <v-col cols="2">
                            <v-list-item-content class="font-weight-bold">{{ gamePromotion.game.name }}:</v-list-item-content>
                        </v-col>
                        <v-col>
                            <v-list-item-content class="align-end">
                                <div
                                  v-if="!$route.params.contractId"
                                  >
                                  {{ gamePromotion.num_players_used }}
                                  /
                                  {{ gamePromotion.num_players }}
                                </div>
                                <div v-else
                                  >
                                  <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    @click="getGameUrl(gamePromotion.id, $route.params.contractId)"
                                    >
                                    Play Here
                                  </v-btn>

                                </div>
                                <!--  /
                                 <pre v-highlightjs>
                                    <code class="json">{{ gamePromotion.config }}</code>
                                </pre>
                                -->
                            </v-list-item-content>
                        </v-col>
                    </v-row>
                </v-list-item>
            </v-list>

            <v-card-title>
            Activity
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
            :headers="headers"
            :items="activity"
            :search="search"
            :loading="loading"
              >
                <!-- This template looks for headers with formatters and executes them -->
                <template
                  v-for="header in headers.filter((header) =>
                    header.hasOwnProperty('formatter')
                  )"
                  v-slot:[`item.${header.value}`]="{ header, value }"
                >
                  {{ header.formatter(value) }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="details(item)"
                    >
                        mdi-eye
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import PromotionStatusIndicator from '@/components/PromotionStatusIndicator'

export default {
  name: 'Show',

  components: { PromotionStatusIndicator },

  data () {
    return {
      dialog: false,
      promotion: null,
      activity: [],
      search: '',
      loading: true,
      stage: process.env.VUE_APP_STAGE,
      promotionKeys: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'promotion_type',
          label: 'Type'
        },
        {
          key: 'title',
          label: 'Promotion Code'
        },
        {
          key: 'priority',
          label: 'Priority'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'players_description',
          label: 'Players\' Description'
        },
        {
          key: 'begins_at',
          label: 'Starting Date'
        },
        {
          key: 'expires_at',
          label: 'Expiration Date'
        },
        {
          key: 'starting_amount',
          label: 'Starting Amount €'
        },
        {
          key: 'win_cap',
          label: 'Win Cap €'
        },
        {
          key: 'promotion_cap',
          label: 'Promotion Cap €'
        },
        {
          key: 'promotion_amount_used',
          label: 'Promotion Amount Used €'
        },
        {
          key: 'nspins',
          label: 'free rounds'
        }
      ],
      headers: [
        {
          value: 'updated_at',
          text: 'Date'
        },
        {
          value: 'contract_number',
          text: 'Contract Number'
        },
        {
          value: 'username',
          text: 'Username'
        },
        {
          value: 'name',
          text: 'Game'
        },
        {
          value: 'win_amount',
          text: 'Amount Win',
          formatter: this.formatCurrency
        },
        {
          value: 'credited_amount',
          text: 'Credited Amount',
          formatter: this.formatCurrency
        },
        {
          value: 'uuid',
          text: 'UUID'
        },
        {
          text: 'Feed',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },

  mounted () {
    this.$axios.get(`api/promotions/${this.$route.params.promotion}`)
      .then(response => {
        this.promotion = response.data
        if (this.promotion.promotion_type === 'free_rounds') {
          if (
            this.promotion.game_promotions &&
            this.promotion.game_promotions[0] &&
            this.promotion.game_promotions[0].config &&
            this.promotion.game_promotions[0].config.fields &&
            this.promotion.game_promotions[0].config.fields.nSpins
          ) {
            this.promotion.nspins = this.promotion.game_promotions[0].config.fields.nSpins
          }
        }
      })

    let endpoint = `api/promotions/activity/${this.$route.params.promotion}`
    if (this.$route.params.contractId) {
      endpoint = endpoint.concat('/', this.$route.params.contractId)
    }
    this.$axios.get(endpoint)
      .then(response => {
        this.activity = response.data
        this.loading = false
      })
  },

  methods: {
    update (promotion) {
      this.$router.push({
        name: 'promotions.update',
        params: { promotion: promotion.id }
      })
    },
    cancel (promotion) {
      this.dialog = false
      this.$axios.post(`api/promotions/cancel/${promotion.id}`).then(response => {
        this.promotion = response.data
      })
    },
    details (item) {
      this.$axios.get(`api/promotions/feed/${item.promo_code}`).then(response => {
        if (response.data.url !== undefined) {
          window.open(response.data.url, '_blank')
        }
      })
    },
    formatCurrency (value) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(value) // '$100.00'
    },

    getGameUrl (gamePromotionId, contractId) {
      console.log('u clicked for link game', gamePromotionId, contractId)
      this.$axios.post(`api/players/${contractId}/promotions/${gamePromotionId}/start`, {
        redirectUrl: 'http://www.google.com/'
      }).then(response => {
        if (response.data.url !== undefined) {
          window.open(response.data.url, '_blank')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
