<template>
    <div>
        <v-card :elevation="5">
            <v-card-title class="subheading font-weight-bold">
                <span class="mr-3">Promotion</span>
            </v-card-title>

             <v-list dense>
                <v-list-item>
                    <v-col cols="3">
                        <v-list-item-content class="font-weight-bold">Title</v-list-item-content>
                    </v-col>
                    <v-col class="p-0">
                        <v-text-field
                            v-model="title"
                            :rules="[value => !!value || 'Field is required.']"></v-text-field>
                    </v-col>
                </v-list-item>

                <v-list-item>
                    <v-col cols="3" class="p-0">
                        <v-list-item-content class="font-weight-bold">Priority</v-list-item-content>
                    </v-col>
                    <v-col class="p-0">
                        <v-text-field v-model.number="priority"
                        type="number"
                        :min="1"
                        :max="10"></v-text-field>
                    </v-col>
                </v-list-item>

                <v-row>
                    <v-list-item>
                        <v-col cols="3">
                            <v-list-item-content class="font-weight-bold">Description</v-list-item-content>
                        </v-col>
                        <v-col>
                            <v-textarea v-model="description" ></v-textarea>
                    </v-col>
                    </v-list-item>
                </v-row>

                <v-row>
                    <v-list-item>
                        <v-col cols="3">
                            <v-list-item-content class="font-weight-bold">Player Description</v-list-item-content>
                        </v-col>
                        <v-col>
                            <v-textarea
                              v-model="players_description"></v-textarea>
                        </v-col>
                    </v-list-item>
                </v-row>

                <v-row>
                    <v-list-item>
                        <v-col cols="3">
                            <v-list-item-content class="font-weight-bold">Starting Date</v-list-item-content>
                        </v-col>
                        <v-col>
                            <v-menu
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="begins_at"
                                        :rules="[ v => !!v || ' date start is required']"
                                        :prefix="'*'"
                                        readonly
                                        v-on="on">
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="begins_at"></v-date-picker>
                            </v-menu>
                    </v-col>
                    </v-list-item>
                </v-row>

                <v-row>
                    <v-list-item>
                        <v-col cols="3">
                            <v-list-item-content class="font-weight-bold">Expiration Date</v-list-item-content>
                        </v-col>
                        <v-col>
                            <v-menu
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="expires_at"
                                        :rules="[ v => !!v || ' date start is required']"
                                        :prefix="'*'"
                                        readonly
                                        v-on="on">
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="expires_at"></v-date-picker>
                            </v-menu>
                    </v-col>
                    </v-list-item>
                </v-row>

                <v-row>
                    <v-list-item>
                        <v-col cols="3">
                            <v-list-item-content class="font-weight-bold">Win Cap</v-list-item-content>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="win_cap"></v-text-field>
                    </v-col>
                    </v-list-item>
                </v-row>

                <v-row>
                    <v-list-item>
                        <v-col cols="3">
                            <v-list-item-content class="font-weight-bold">Promotion Cap</v-list-item-content>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="promotion_cap"></v-text-field>
                    </v-col>
                    </v-list-item>
                </v-row>

                <v-row>
                  <v-list-item>
                    <v-col cols="3">
                        <v-list-item-content class="font-weight-bold">Players</v-list-item-content>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        v-model="selectedPlayers"
                        :items="players"
                        label="Players"
                        item-text="contract_number"
                        item-value="id"
                        multiple
                        chips
                        required
                        auto-select-first
                      >
                        <template #selection="{ item }">
                          <v-chip
                            :disabled="item.disabled"
                            :close="!item.disabled"
                            @click:close="deleteSelectPlayer(item)"
                            >
                              {{item.username}} {{ item.contract_number }}
                            </v-chip>
                        </template>
                        <template v-slot:prepend-item>
                            <v-list-item
                                ripple
                                @click="togglePlayersSelection"
                            >
                                <v-list-item-action>
                                    <v-icon :color="selectedPlayers.length > 0 ? 'indigo darken-4' : ''">
                                        {{ iconPlayers }}
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Select All
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-list-item>
                </v-row>
             </v-list>

        </v-card>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="updatePromotion"
                v-if="!loading"
            >
                Save
            </v-btn>
        </v-card-actions>
    </div>
</template>

<script>

export default {
  name: 'Update',

  data () {
    return {
      title: null,
      win_cap: null,
      promotion_cap: null,
      priority: null,
      description: null,
      players_description: null,
      begins_at: null,
      expires_at: null,
      players: [],
      selectedPlayers: [],
      disabledPlayers: [],
      game_promotions: [],
      loading: true,
      is_player_locked: null,
      promotionKeys: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'promotion_type',
          label: 'Type'
        },
        {
          key: 'title',
          label: 'Title'
        },
        {
          key: 'priority',
          label: 'Priority'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'players_description',
          label: 'Players\' Description'
        },
        {
          key: 'begins_at',
          label: 'Starting Date'
        },
        {
          key: 'expires_at',
          label: 'Expiration Date'
        },
        {
          key: 'starting_amount',
          label: 'Starting Amount €'
        },
        {
          key: 'win_cap',
          label: 'Win Cap €'
        },
        {
          key: 'promotion_cap',
          label: 'Promotion Cap €'
        },
        {
          key: 'promotion_amount_used',
          label: 'Promotion Amount Used €'
        }
      ]
    }
  },

  mounted () {
    this.$axios.get(`api/promotions/${this.$route.params.promotion}`)
      .then(response => {
        if (!['active', 'upcoming'].includes(response.data.status)) {
          this.$router.push({
            name: 'promotions.index'
          })
        }

        this.title = response.data.title
        this.win_cap = response.data.win_cap
        this.promotion_cap = response.data.promotion_cap
        this.priority = response.data.priority
        this.description = response.data.description
        this.players_description = response.data.players_description
        this.begins_at = response.data.begins_at.split('-').reverse().join('-')
        this.expires_at = response.data.expires_at.split('-').reverse().join('-')
        this.selectedPlayers = response.data.game_promotions[0].players.map(p => p.id)
        this.selectedPlayersFull = response.data.game_promotions[0].players
        this.game_promotions = response.data.game_promotions
        this.is_player_locked = response.data.is_player_locked

        this.fetchPlayers()
      })
  },

  methods: {
    updatePromotion () {
      if (this.loading) {
        return false
      }

      this.$axios.post(`api/promotions/update/${this.$route.params.promotion}`, this.promotionFormData()).then(response => {
        this.$router.push({
          name: 'promotions.show',
          params: { promotion: this.$route.params.promotion }
        })
      })
    },
    promotionFormData () {
      const formData = {}
      formData.title = this.title
      formData.win_cap = this.win_cap
      formData.promotion_cap = this.promotion_cap
      formData.priority = this.priority
      formData.description = this.description
      formData.players_description = this.players_description
      formData.begins_at = this.begins_at
      formData.expires_at = this.expires_at
      formData.players = this.selectedPlayers

      return formData
    },
    fetchPlayers () {
      if (this.is_player_locked) {
        console.log('promotion is player locked yo')
        console.log(this.selectedPlayersFull)
        this.players = this.selectedPlayersFull
      } else {
        this.$axios.put('api/players').then(response => {
          this.players = response.data
        })
      }

      this.game_promotions.forEach(gp => {
        this.disabledPlayers = gp.players.filter(player => player.game_promotion_data.promo_code !== null).map(p => p.id)
        console.log(this.disabledPlayers)
        this.players.forEach(p => {
          if (this.disabledPlayers.includes(p.id)) {
            p.disabled = true
          }
        })
      })
      this.loading = false
    },

    togglePlayersSelection () {
      this.$nextTick(() => {
        if (this.selectedPlayers.length === this.players.length) {
          this.selectedPlayers = []
          this.selectedPlayers = this.disabledPlayers
        } else {
          this.selectedPlayers = this.players.map(player => player.id)
        }
      })
    },

    deleteSelectPlayer (player) {
      if (confirm(`Are you sure that you want to remove the player ${player.username} ${player.contract_number}?`)) {
        const index = this.selectedPlayers.indexOf(player.id)
        if (index > -1) {
          this.selectedPlayers.splice(index, 1)
        }
      }
    }
  },

  computed: {
    allPlayersSelected () {
      return this.selectedPlayers.length === this.players.length
    },
    somePlayersSelected () {
      return this.selectedPlayers.length > 0 && !this.allPlayersSelected
    },
    iconPlayers () {
      if (this.allPlayersSelected) return 'mdi-close-box'
      if (this.somePlayersSelected) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  }
}
</script>

<style scoped>

</style>
